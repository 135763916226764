<template>
  <div>
    <!-- Invoice Modal -->
    <div id="fieldFaturamento">
      <h4 class="">Selecione o tipo do arquivo</h4>
      <!-- Archive Kind Button -->
      <div class="w-100 mb-3">
        <b-button-group v-model="archiveKind" size="lg" class="w-100">
          <b-button @click="selectArchiveKind($event)" value="esocial">E-social</b-button>
          <b-button @click="selectArchiveKind($event)" value="icms">Imcs</b-button>
          <b-button @click="selectArchiveKind($event)" value="piscofins">Pis cofins</b-button>
          <b-button @click="selectArchiveKind($event)" value="sefipinterno">Sefip Interno</b-button>
          <b-button @click="selectArchiveKind($event)" value="sefipexterno">Sefip Externo</b-button>
        </b-button-group>

        <div v-if="archiveKind" class="mt-2">
          Tipo do arquivo selecionado:
          <span style="font-weight: bold" v-if="archiveKind == 'esocial'">E-social</span>
          <span style="font-weight: bold" v-else-if="archiveKind == 'icms'">ICMS</span>
          <span style="font-weight: bold" v-else-if="archiveKind == 'piscofins'">Pis Cofins</span>
          <span style="font-weight: bold" v-else-if="archiveKind == 'sefipinterno'">Sefip Interno</span>
          <span style="font-weight: bold" v-else-if="archiveKind == 'sefipexterno'">Sefip Externo</span>
        </div>
      </div>
      <!-- Archive Kind Button END -->
      <div id="dataSet">
        <p class="text-success h6 text-center"></p>
        <!-- Field Dropzone -->
        <div id="fieldArchiveKind" class="w-100" style="display: none">
          <div id="campPath">
            <div id="dropzoneContainer" style="transition: 0.3s">
              <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" :useCustomSlot="true">
                <div class="dropzone-custom-content">
                  <h4 class="dropzone-custom-title">
                    Arraste e solte aqui seus arquivos.
                  </h4>
                  <div class="subtitle">
                    ... ou clique para abrir o diretório.
                  </div>
                  <font-awesome-icon size="2x" icon="upload"></font-awesome-icon>
                </div>
              </vue-dropzone>
            </div>
          </div>
        </div>
        <!-- Field Dropzone END -->
        <!-- Field SefipExterno -->
        <div id="refSefipExt" style="display: none">
          <money class="pl-2 inputSefipExt w-50" v-model="inputSefipEx" v-bind="money"
            style="transition: 0.3s; color: #444"></money>
          <div class="center w-50">
            <h4>
              Mês referente:
              {{
                  dados.fasesStatus.statusCompensacoes[
                    dados.fasesStatus.statusCompensacoes.length - 1
                  ].mesAnoReferencia
              }}
            </h4>
          </div>
        </div>
        <!-- Field SefipExterno END -->
        <!-- Error messege field  -->
        <div id="msgError" class="pt-2 center h6 text-danger" style="transition: 0.3s"></div>
        <!-- Error messege field END-->
        <!-- Send button END -->
        <b-button id="btnSend" variant="primary" class="d-none chamada w-100 mt-2" @click="callBeforeRequest()"><span
            class="h6">Enviar</span></b-button>
        <!-- Send button END -->
      </div>
    </div>
    <!-- Invoice Modal END -->
    <!-- Data return screen  -->
    <div id="fieldReturn" class="border d-none flex-column">
      <h4 class="center border-bottom pt-1">Informações</h4>
      <div v-if="hasContent">
        <div class="w-100 mb-1 p-3 border-bottom animation">
          <div class="w-50 d-inline-block h6">
            CNPJ: {{ returnPost.data.cnpj }}
          </div>
          <div class="w-50 d-inline-block h6">
            Contribuição: {{ returnPost.data.contribuite }}
          </div>
        </div>
        <div class="w-100 p-3 border-bottom animation">
          <div class="w-50 d-inline-block h6">
            Periodo: {{ returnPost.data.periodo }}
          </div>
          <div class="w-50 d-inline-block h6">
            Valor compensado: R${{ returnPost.data.valorCompensado }}
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-success h4 text-center p-1 animation">
          Concluído
          <font-awesome-icon size="1x" icon="check"></font-awesome-icon>
        </p>
      </div>
      <b-button style="background-color: rgb(34, 93, 37)" class="chamada w-100" @click="btnSendFaturamento">Enviar para
        Faturamento</b-button>
    </div>
    <!-- Data return screen END -->
  </div>
</template>

<script>
import UploadInssService from "../services/uploadInss.service";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { Money } from "v-money";
export default {
  components: { vueDropzone: vue2Dropzone, Money },
  data() {
    return {
      busy: false,
      archiveKind: "",
      inputSefipEx: "",
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        addRemoveLinks: true,
        paralleUploads: 1,
        autoProcessQueue: false,
        maxFiles: 4,
      },
      price: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      files: "",
      returnPost: {},
      hasContent: false,
    };
  },
  props: {
    dados: { Type: Object },
    modalAvaçarStatus: { Type: Object },
    modalFaturamento: { Type: Object },
    idUser: { Type: String },
  },

  methods: {
    //
    selectArchiveKind(event) {
      document.querySelector("#btnSend").classList.add("d-block");
      document.getElementById("msgError").innerHTML = "";
      switch (event.target.value) {
        case "esocial":
          this.msgTypeFile(
            "Insira o arquivo RELATÓRIO RESUMO DE CRÉDITOS – DCTFWeb"
          );
          this.archiveKind = event.target.value;
          break;
        case "icms":
          this.msgTypeFile("Insira o arquivo EFD ICMS E IPI ORIGINAL");
          this.archiveKind = event.target.value;
          break;
        case "piscofins":
          this.msgTypeFile("Insira o arquivo EFD CONTRIBUIÇÔES ORIGINAL");
          this.archiveKind = event.target.value;
          break;
        case "sefipinterno":
          this.msgTypeFile("Insira o arquivo RELATÓRIO DE COMPENSAÇÕES");
          this.archiveKind = event.target.value;
          break;
        case "sefipexterno":
          this.msgTypeFile("");
          this.archiveKind = event.target.value;
          break;
      }
    },

    msgTypeFile(msg) {
      document.querySelector("#dataSet p").innerHTML = msg;
      if (msg != "") {
        this.showFieldArcheviKind();
      } else {
        this.showRefSefipExt();
      }
    },

    showFieldArcheviKind() {
      document.querySelector("#fieldArchiveKind").style.display = "block";
      document.querySelector("#refSefipExt").style.display = "none";
      this.$refs.myVueDropzone.removeAllFiles();
      this.cleanInput();
    },

    showRefSefipExt() {
      document.querySelector("#refSefipExt").style.display = "flex";
      document.querySelector("#fieldArchiveKind").style.display = "none";
      this.$refs.myVueDropzone.removeAllFiles();
      this.cleanInput();
    },

    callBeforeRequest() {
      this.files = this.$refs.myVueDropzone.getAcceptedFiles();
      if (
        document.querySelector("#fieldArchiveKind").style.display == "block"
      ) {
        this.files == ""
          ? this.messageError("Carregue seus arquivos", "#dropzoneContainer")
          : this.requestCallBack();
      } else if (
        document.querySelector("#refSefipExt").style.display == "flex"
      ) {
        this.inputSefipEx == ""
          ? this.messageError(
            "Preencha os campos necessarios",
            ".inputSefipExt"
          )
          : this.requestCallBack();
      }
    },

    cleanInput() {
      this.inputSefipEx = "";
    },

    messageError(messege, field) {
      document.getElementById("msgError").style.opacity = "1";
      document.getElementById("msgError").innerHTML = messege;
      document.querySelector(field).style.border = "1px solid red";
      setTimeout(() => {
        document.getElementById("msgError").style.opacity = "0";
        document.querySelector(field).style.border = "1px solid #ccc";
        setTimeout(() => {
          document.getElementById("msgError").innerHTML = "";
        }, 100);
      }, 2500);
      this.cleanInput();
    },

    requestCallBack() {
      this.$refs.myVueDropzone.processQueue();
      if (this.archiveKind != "sefipexterno") {
        UploadInssService.uploadFilesFaturamento(
          this.files,
          this.archiveKind,
          this.idUser,
          this.dados
        ).then((response) => {
          this.returnPost = response;
          if (response.status == 200) {
            this.showScreenReturn();
            if (this.returnPost.data.cnpj) {
              this.hasContent = true;
            }
          }
        });
      } else {
        UploadInssService.uploadFilesFaturamentoSefipExt(
          this.archiveKind,
          this.idUser,
          this.dados,
          this.inputSefipEx
        ).then((response) => {
          this.returnPost = response;
          if (response.status == 200) {
            this.showScreenReturn();
            if (this.returnPost.data.cnpj) {
              this.hasContent = true;
            }
          }
        });
      }
      this.cleanInput();
    },

    btnSendFaturamento() {
      this.modalAvaçarStatus.show();
      setTimeout(() => {
        this.modalFaturamento.hide();
      }, 10);
    },

    showScreenReturn() {
      document.querySelector("#fieldFaturamento").classList.add("d-none");
      document.querySelector("#fieldReturn").classList.remove("d-none");
      document.querySelector("#fieldReturn").classList.add("d-flex");
      document.querySelector("#fieldReturn").classList.add("d-flex");
    },
  },
};
</script>

<style scoped>
#dropzoneFaturamento {
  /*border: 2px solid  rgba(48, 169, 43, 0.5); rgba(35, 56, 115, 1);*/
  color: #555 !important;
  background-color: #ddd;
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #3da938, rgba(35, 56, 115, 1));
  border-image-slice: 1;
  text-align: center;
}

.inputSefipExt {
  outline: 0;
  border: 1px solid #ccc;
}

.animation {
  opacity: 1;
  animation: check 1.5s;
}

@keyframes check {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
