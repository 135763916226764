<template>
  <section  id="homepage">
    <div class="cabecalho justify-content-between">
      <Breadcrumb class=""></Breadcrumb>
      <Filtro class="cabecalho-inputs d-flex row" @submit-filter="oportunidades($event)" @busy="isBusyFilter"
        :loadingFilter="isBusyFilter" :numberPagination="currentPage" :Pages="perPage"  
      />
    </div>
    <section class="container-fluid jumbotron">
      <header>
        
      </header>

      <b-table class="mt-2" :busy="isBusy" :fields="fields" :items="items" show-empty :per-page="perPage"
        :current-page="1" :sort-desc="true" sort-icon-left responsive>
        
        <template #table-busy>
          <div class="text-center my-3">
            <h3>
              <b-spinner class="align-middle"></b-spinner>
              Carregando oportunidades...
            </h3>
          </div>
        </template>

        <template #cell(ncodop)="data">
          <div @click="popUp(data.item.codOportunidade)" :id="data.item.codOportunidade" class="td text-center d-flex flex-wrap-nowrap">
            <font-awesome-icon icon="copy" class="mx-1"></font-awesome-icon>{{ data.item.codOportunidade }}
          </div>
        </template>

        <!-- v-b-modal.modalOportunidade -->
        <template #cell(nome_empresa)="data">
          <div class="td" @click.prevent="detailOp(data.item)">
            {{ data.item.nomeEmpresa }}
          </div>
        </template>

        <template #cell(descricao_oportunidade)="data">
          <div class="td" @click.prevent="detailOp(data.item)">
            {{ data.item.descricaoSolucao }}
          </div>
        </template>

        <template #cell(email)="data">
          <div class="td" @click.prevent="detailOp(data.item)">
            {{ data.item.email.split(',')[0]}}
          </div>
        </template>
        <template #cell(status_op)="data">
          <div class="td" @click.prevent="detailOp(data.item)">
            {{ data.item.status }}
          </div>
        </template>
        <template #cell(data_alter)="data">
          <div class="td" @click.prevent="detailOp(data.item)">
            {{ (data.item.dataAlteracao.split(" ", 1)[0]) }} - {{ data.item.horaAlteracao }}
            <br />
          </div>
        </template>
        <template #cell(responsavel)="data">
          <div class="td" @click.prevent="detailOp(data.item)">
            {{ data.item.nomeVendedor }}
          </div>
        </template>

        <template #empty="teste">
          <h4 class="text-center">Nenhuma oportunidade encontrada. :(</h4>
        </template>

      </b-table>

      <!-- Start - Pagination -->
      <section>
        <div class="row">
          <b-pagination class="col-6" v-model="currentPage" :total-rows="totalNumberOfOpportunities" :per-page="perPage"
            first-number last-number align="start"></b-pagination>

          <div class="col text-right">
            <span class="h6">Total de oportunidades: {{ totalNumberOfOpportunities }}
            </span>
          </div>
        </div>
      </section>
      <!-- End - Pagination -->
    </section>
    <DetailOportunidadeHome :detail="detail" :openModalOp="openModalOp" :itemsTable="items"
      @resetModal="openModalOp = $event" />

    <!-- Modal Enviar para faturamento -->
    <b-modal id="modal-lg" size="lg" title="Faturamento" ref="modal-faturamento" centered hide-footer>
      <TelasFaturamento :dados="detail" :modalAvaçarStatus="this.$refs['modal-avancar-status']"
        :modalFaturamento="this.$refs['modal-faturamento']" :idUser="this.$store.state.auth.user.id" />
    </b-modal>
  </section>
</template>

<script>
import DetailOportunidadeHome from "./../../Components/DetailOportunidadeHome/DetailOportunidadeHome.vue";
import filters from "../../Components/HomeComponents/HomeFilters.vue";
import HomeMethods from "../../Utilities/HomeMethods";
import HomeService from "../../services/home.service";
import OportunidadeService from "@/services/oportunidade.service";
import TelasFaturamento from "../TelasFaturamento.vue";
import emitter from "../../services/emitter";
import Icon from '../../Components/Icon/icon.vue';
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb.vue";

export default {
  name: "Home",

  components: { Filtro: filters, TelasFaturamento, DetailOportunidadeHome, Breadcrumb, Icon },

  data() {
    return {
      show: false,
      perPage: 15,
      copy: [],
      currentPage: 1,
      isBusy: false,
      totalNumberOfOpportunities: 0,
      openModalOp: false,
      statusComp: [
        "Início da compensação",
        "Aguardando documentação para compensação.",
        "Em processo de compensação.",
        "Em processo de faturamento.",
        "Faturado.",
      ],
      items: [],

      fields: [
        { key: "ncodop", label: "Código", sortable: true },
        {
          key: "nome_empresa",
          label: "Nome da empresa",
          sortable: true,
        },
        {
          key: "descricao_oportunidade",
          label: "Descrição Op",
          sortable: true,
        },
        { key: "email", label: "Email", sortable: true },
        {
          key: "status_op",
          label: "Status da oportunidade",
          sortable: true,
        },
        {
          key: "data_alter",
          label: "Ultima atualização",
          sortable: true,
        },
        {
          key: "responsavel",
          label: "Responsável",
          sortable: true,
        },
      ],

      detail: {
        tese: { tesePrimaria: null, teseSecundaria: null },
        perm: false,
        inComp: false,
        identificacao: {},
        fasesStatus: {},
        ticket: {},
        previsaoTemp: {},
        observacoes: {},
        outrasInf: {},
      },

      btnCompStt: {
        color: "#000",
        nome: "documentos recbidos",
      },
    };
  },

  created() {
    this.isBusy = true;
  },

  mounted() {
    let background = document.getElementById("fundo");
    background.style.backgroundColor = "#303086";
  },

  updated() {
    let background = document.getElementById("fundo");
    background.style.backgroundColor = "#303086";
  },

  watch: {
    currentPage(newValue) {
      newValue ? (this.isBusy = true) : (this.isBusy = false);
    },
  },

  methods: {
    popUp(nCodOp) {
      if (nCodOp != null) {
        var textArea = document.createElement("textarea");
        textArea.style.position = "fixed";
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = "2em";
        textArea.style.height = "2em";
        textArea.style.padding = 0;
        textArea.style.border = "none";
        textArea.style.outline = "none";
        textArea.style.boxShadow = "none";
        textArea.style.background = "transparent";
        textArea.value = nCodOp;

        document.body.appendChild(textArea);
        textArea.select();

        try {
          document.execCommand("copy");
          this.$swal({
            position: "top-center",
            icon: "success",
            text: "Copiado com sucesso!",
            showConfirmButton: false,
            timer: 1100,
          });
        } catch (err) {
          window.prompt(
            "Copie para área de transferência: Ctrl+C e tecle Enter",
            nCodOp
          );
        }

        document.body.removeChild(textArea);
      }
    },

    formatDateBr(datetoformat){
      var splitdate = datetoformat.split('/');
      var formatedDate = splitdate[1] + "/" + splitdate[0] + "/" + splitdate[2];
      console.log(formatedDate);
      return formatedDate
    },

    isBusyFilter(isBusy) {
      this.isBusy = isBusy;
      if (isBusy) {
        this.currentPage = 1;
        return (this.totalNumberOfOpportunities = 0);
      }
      this.currentPage = this.currentPage;
    },

    oportunidades(dataOp) {
      if (dataOp) {
        this.items = dataOp.dados.oportunidades;
        return (this.totalNumberOfOpportunities = dataOp.dados.totalRegistros);
      }
    },

    formatDate(date) {
      let dateFormated = " ";
      if (date != null || date != " ") {
        dateFormated =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear();
      } else {
        this.$swal({
          position: "top-center",
          icon: "error",
          text: "Insira uma data válida!",
          showConfirmButton: false,
          timer: 1100,
        });
      }
      return dateFormated;
    },

    detailOp(oportunidade) {
      //desativado modal para testes 
      // this.openModalOp = true;

      // this.detail = HomeMethods.detailOp(
      //   oportunidade,
      //   this.btnCompStt,
      //   this.$store.state.auth.user.roles
      // );

      // console.log("this detail -> ", this.detail);

      // if (this.detail.status.trim() == "CONCLUSÃO") {
      //   let updateBtn = HomeMethods.btnStatus(
      //     oportunidade,
      //     this.btnCompStt,
      //     this.$store.state.auth.user.username
      //   );
      //   this.btnCompStt = updateBtn.btn;
      //   let tese = this.detail.descricaoSolucao.split("-")[1].trim();
        
      //   if (tese.split("+")[0].trim() == "inss") {
      //     this.detail.tese = { tesePrimaria: "Inss" };
      //   } else {
      //     switch (tese.split("+")[0].trim()) {
      //       case "PIS COFINS INSUMOS":
      //         this.detail.tese = { tesePrimaria: "Restituicao" };
      //         break;
      //       case "EXCLUSÃO DE ICMS PIS COFINS":
      //         this.detail.tese = { tesePrimaria: "Icms" };
      //         break;
      //     }
      //   }

      //   if (tese.includes("+")) {
      //     let tese2 = tese.split("+")[1].trim();
      //     switch (tese2) {
      //       case "PIS COFINS INSUMOS":
      //         this.detail.tese = {
      //           tesePrimaria: this.detail.tese.tesePrimaria,
      //           teseSecundaria: "Restituicao",
      //         };
      //         break;
      //       case "EXCLUSÃO DE ICMS PIS COFINS":
      //         this.detail.tese = {
      //           tesePrimaria: this.detail.tese.tesePrimaria,
      //           teseSecundaria: "icms",
      //         };
      //         break;
      //     }
      //   }

      // }
      // console.log("2 this detail -> ", this.detail);
      // emitter.emit("newValueDetail", this.detail)
    },

    modalConfirm() {
      let element = this.$createElement;
      let msg = element("p", { class: ["card-text"] }, [
        `Você realmente deseja avançar para o status " ${this.statusComp[
        this.detail.fasesStatus.statusCompensacoes[
          this.detail.fasesStatus.statusCompensacoes.length - 1
        ].statusCompensacaoMes[
          this.detail.fasesStatus.statusCompensacoes[
            this.detail.fasesStatus.statusCompensacoes.length - 1
          ].statusCompensacaoMes.length - 1
        ].idStatusCompensacao + 1
        ]
        }" ?`,
      ]);
      this.$bvModal
        .msgBoxConfirm([msg], {
          title: "Avançar status da compensação",
          okVariant: "outline-success",
          okTitle: "Confirmar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          bodyClass: "modal-body-confirm",
          footerClass: "modal-footer",
        })
        .then((response) => {
          if (response === true) {
            this.updateCompStatus();
          }
        });
    },

    closeModal() {
      this.$refs["modalOportunidade"].hide();
    },
  },
};
</script>

<style src="./HomeStyles.scss" lang="scss">