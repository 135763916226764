import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class UploadInssService {
  
  async uploadFiles(files, tese, nCodOp, ajustarPeriodo, validaPrefeitura, isIrpps, cutManad, periodos ) {
    let file = new FormData();
    file.append("Files", files);
    file.append("NCodOp", nCodOp);
    file.append("AjustarPeriodo", ajustarPeriodo);
    file.append("ValidaPrefeitura", validaPrefeitura);
    file.append("IsRPPS", isIrpps);
    file.append("CutManad", cutManad);
    file.append("Periodos", periodos);
    return await axios.post(`${API_URL}/${tese}/upload/`, file);
  }

  uploadFilesFaturamento(files, archiveKind, idUser, dados) {
    let file = new FormData();
    files.forEach((element) => {
    file.append("File", element);
    });
    file.append("ArchiveKind", archiveKind);
    file.append("IdUser", idUser);
    file.append("NCodOp", dados.nCodOp);
    return axios.post(`${API_URL()}/oportunidade/leitura/`, file);
  }

  uploadFilesFaturamentoSefipExt(archiveKind, idUser, dados, inputSefipExt) {
    let file = new FormData();
    file.append("ArchiveKind", archiveKind);
    file.append("IdUser", idUser);
    file.append("NCodOp", dados.nCodOp);
    file.append("ValorCompensado", inputSefipExt);
    file.append(
      "MesReferencia",
      dados.fasesStatus.statusCompensacoes[
        dados.fasesStatus.statusCompensacoes.length - 1
      ].mesAnoReferencia
    );
    return axios.post(`${API_URL}/oportunidade/leitura/`, file);
  }

  getInsumos(nCodOp, passo) {
    let params = {
      NCodOp: nCodOp,
      Passo: passo,
    };
    return axios.get(`${API_URL}/Restituicao/getinsumos/`, {params});
  }

  processarCalcInss(params) {
    console.log("Params: ", params);
    return axios.post(`${API_URL}/inss/process/`, params);
  }

  processarDeleteInss(nCodOp, codigoRubrica, cnpj) {
    let params = {
      NCodOp: nCodOp,
      CodRubrica: codigoRubrica,
      Cnpj: cnpj,
    };
    return axios.post(`${API_URL}/inss/deleteRubricaInss/`,params);
  }

  processarDeleteOutrasEntidades(nCodOp, nivel, cnpj) {
    let params = {
      NCodOp: nCodOp,
      IdNivel: nivel,
      Cnpj: cnpj,
    };
    return axios.post(`${API_URL}/inss/deleteOutrasEntidades/`,params);
  }

  // processarDeleteInss(nCodOp, codigoRubrica, this.cnpj) {
  //   console.log("nCodOp - ",nCodOp);
  //   console.log("codigoRubrica - ",codigoRubrica);
  //   return axios.post(`${API_URL}/inss/deleteRubricaInss/`, nCodOp, codigoRubrica);
  // }

  getNotas(nCodOp, selecteds) {
    return axios.post(`${API_URL}/Restituicao/getnotas/`, {
      NCodOp: nCodOp,
      CodigosCta: selecteds,
    });
  }

  async getFilesSended(nCodOp) {
    let params = {
      NCodOp: nCodOp,
    };
    return await axios.get(`${API_URL}/inss/getfilessended/`, {params});
  }


  // async apagarTudo(nCodOp) {
  //   let params = {
  //     NCodOp: nCodOp,
  //   };  
  //   var heads={ crossDomain: true, "Content-Type": "application/json" }
  //   return await axios.delete(`${API_URL}/Restituicao/deleteAllFilesSped/`, {params}, {headers:heads});  
  // }

  async apagarExcel(nCodOp) {
    let params = {
      NCodOp: nCodOp,
    };  
    var heads={ crossDomain: true, "Content-Type": "application/json" }
    return await axios.delete(`${API_URL}/Restituicao/deleteExcelFile/`, {params}, {headers:heads});  
  }
}

export default new UploadInssService();
