var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"filtros"}},[_c('b-form',{staticClass:"d-flex justify-content-between cabecalho-inputs",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendFilter()}}},[_c('div',{staticClass:"cabecalho-tools d-flex justify-content-between flex-row",attrs:{"id":"filter-input-container"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nomeEmpresa),expression:"form.nomeEmpresa"}],staticClass:"cabecalho-input",attrs:{"id":"filter-input","type":"text","placeholder":"Empresa, CNPJ, Email..."},domProps:{"value":(_vm.form.nomeEmpresa)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "nomeEmpresa", $event.target.value)}}}),_c('button',{staticClass:"btn",attrs:{"disabled":_vm.loadingFilter,"type":"submit","variant":"dark"}},[_c('Icon',{staticClass:"nav-icon",attrs:{"iconName":"search"}})],1)]),_c('div',{staticClass:"cabecalho-tools cabecalho-btn"},[_c('b-form-select',{staticClass:"cabecalho-input",attrs:{"id":"cabecalho-dropdown","options":[
            { value: null, text: 'Todas oportunidades' },
            'LEAD',
            'COBRAR DOCS',
            'ESTUDO DE VIABILIDAD',
            'APRESENTAÇÃO',
            'NEGOCIAÇÃO',
            'CONCLUSÃO' ],"value":null},model:{value:(_vm.form.statusOp),callback:function ($$v) {_vm.$set(_vm.form, "statusOp", $$v)},expression:"form.statusOp"}})],1),_c('div',{},[(_vm.form.nomeEmpresa || _vm.form.statusOp )?_c('button',{staticClass:"cabecalho-tools cabecalho-btn",on:{"click":function($event){$event.preventDefault();return _vm.clearFilter($event)}}},[_c('Icon',{staticClass:"nav-icon",attrs:{"iconName":"x-lg"}})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }