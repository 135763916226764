import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class OportunidadeService {
  attrOp(user, op) {
    return axios.post(`${API_URL}/oportunidade/atribuir`, {
      Idresponsavel: user.id,
      NCodOp: op.nCodOp,
    });
  }
  desattrOp(omie, op) {
    return axios.post(`${API_URL}/oportunidade/atribuir`, {
      Idresponsavel: omie,
      NCodOp: op.nCodOp,
    });
  }
}
export default new OportunidadeService();
