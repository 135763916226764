class HomeMethods {
  detailOp(oportunidade, btn, user) {
    if (oportunidade) {
      if (oportunidade.status === "CONCLUSÃO") {
        oportunidade.inComp = true;
        this.btnStatus(oportunidade, btn, user);
      }
      return oportunidade;
    }
  }

  btnStatus(oportunidade, btn, user) {
    if (oportunidade) {
      oportunidade.fasesStatus.statusCompensacoes.forEach((element) => {
        const pos = element.statusCompensacaoMes.length - 1;
        const statusCompoensacao =
          element.statusCompensacaoMes[pos].idStatusCompensacao;

        switch (statusCompoensacao) {
          case 1:
            btn.nome = "Documentos recebidos";
            btn.color = "#225d25";
            if (user == "Auditoria" || user == "Admin") {
              oportunidade.perm = true;
            } else {
              oportunidade.perm = false;
            }
            break;
          case 2:
            btn.nome = "Enviar para faturamento";
            btn.color = "#406de7";
            if (user == "Auditoria" || user == "Admin") {
              oportunidade.perm = true;
            } else {
              oportunidade.perm = false;
            }
            break;
          case 3:
            btn.nome = "Créditos faturados";
            btn.color = "#3da938";
            if (user == "Financeiro" || user == "Admin") {
              oportunidade.perm = true;
            } else {
              oportunidade.perm = false;
            }
            break;
        }
      });

      return { oportunidade, btn };
    }
  }
}

export default new HomeMethods();
