<template>
  <section>
    <!-- Start - Modal detalhes da opotunidade -->
    <b-modal :title="detail.DescricaoSolucao" size="xl" id="modalOportunidade" ref="modalOportunidade" hide-footer
      centered>
      <b-card no-body>
        <b-tabs justified card active-nav-item-class="font-weight-bold text-uppercase">
          <b-tab>
            <template #title>
              <font-awesome-icon icon="info-circle"></font-awesome-icon>
              <strong> Informações</strong>
            </template>
            <div class="py-2">
              <div>
                <div>
                  <strong>Status oportunidade:</strong>
                  {{ detail.status }}
                </div>
                <div v-if="detail.inComp">
                  <strong>Status compensação: </strong>
                  {{ StatusCompensacao }}
                </div>
              </div>
              <div>
                <strong>Email:</strong>
                <span v-if="detail.email">
                  {{ detail.email }}
                  <font-awesome-icon icon="external-link-alt"></font-awesome-icon>
                </span>
                <span v-else> Email não cadastrado </span>
              </div>
              <div>
                <strong>Ultima alteração:</strong>
                <span>
                  {{ FormatDateHours }} | {{ detail.outrasInf.hAlteracao }}
                </span>
              </div>
              <div>
                <strong>Data de inclusão:</strong>
                {{ detail.outrasInf.dataHoraAlteracao }}
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <div v-if="
                this.$store.state.auth.user.roles === 'Auditoria' ||
                this.$store.state.auth.user.roles === 'Financeiro' ||
                this.$store.state.auth.user.roles === 'Comercial' ||
                this.$store.state.auth.user.roles === 'Admin'
              ">
                <a v-if="
                  detail.outrasInf.responsavel !=
                  this.$store.state.auth.user.username
                " class="btn-attr" @click.prevent.stop="assignOportunidade($store.state.auth.user, detail)">
                  <font-awesome-icon icon="plus"></font-awesome-icon>
                  Atribuir à mim
                </a>
                <a v-if="
                  detail.outrasInf.responsavel ==
                  this.$store.state.auth.user.username
                " class="btn-attr" @click.prevent.stop="unassignOportunidade(8, detail)">
                  <font-awesome-icon icon="minus"></font-awesome-icon>
                  Desatribuir de mim
                </a>
              </div>

              <div>
                <button type="button" class="btn btn-secondary mx-1"
                  @click.prevent="$bvModal.hide('modalOportunidade')">
                  Fechar
                </button>

                <b-button type="button" class="text-light mx-1" @click="modifiedModal()" v-if="detail.perm"
                  :style="{ backgroundColor: btnCompStt.color }">
                  {{ btnCompStt.nome }}
                </b-button>

                <button v-b-modal.modal-select-calculo id="btnStatus" v-if="
                  (this.$store.state.auth.user.roles == 'Admin' ||
                    this.$store.state.auth.user.roles == 'Auditoria') &&
                  this.detail.fasesStatus.descFase == 'Aguardando cálculo'
                " type="button" class="btn btn-primary mx-1" data-dismiss="modal">
                  Efetuar calculo
                </button>

                <button id="btnStatus" v-if="
                  (this.$store.state.auth.user.roles == 'Admin' ||
                    this.$store.state.auth.user.roles == 'Auditoria') &&
                  this.detail.fasesStatus.descFase == 'Em compensação'
                " @click.stop.prevent="editCalc()" type="button" class="btn btn-primary mx-1">
                  Editar cálculo
                </button>
              </div>
            </div>
          </b-tab>

          <!-- Modal selecionar tese para cálculo -->
          <b-modal title="Você deseja realizar qual cálculo?" id="modal-select-calculo" hide-footer>
            <template #default>
              <div class="center justified">
                <router-link :to="`/calculo/${detail.tese.teseSecundaria}/${detail.nCodOp}`"
                  class="btn btn-outline-blue">
                  {{ detail.tese.teseSecundaria }}
                </router-link>
                <router-link v-if="detail.tese.tesePrimaria" :to="{
                  name: 'calculo',
                  params: {
                    tese: detail.tese.tesePrimaria,
                    nCodOp: detail.nCodOp,
                  },
                }" class="btn btn-outline-green">
                  {{ detail.tese.tesePrimaria }}
                </router-link>
              </div>
            </template>
          </b-modal>

          <!-- Modal avançar status -->
          <b-modal title="Avançar status" id="modal-avancar-status" ref="modal-avancar-status" hide-footer>
            <template #default>
              <div class="center justified">
                <p>
                  Você realmente deseja avançar para o status "
                  <span style="font-weight: bold">
                    {{
                        statusComp[
                          detail.fasesStatus.statusCompensacoes[
                            detail.fasesStatus.statusCompensacoes.length - 1
                          ].statusCompensacaoMes[
                            detail.fasesStatus.statusCompensacoes[
                              detail.fasesStatus.statusCompensacoes.length - 1
                            ].statusCompensacaoMes.length - 1
                          ].idStatusCompensacao + 1
                        ].trim()
                    }}
                  </span>
                  " ?
                </p>
              </div>
              <b-overlay :show="isBusy" rounded="sm">
                <div class="modal-footer">
                  <b-button @click="$bvModal.hide('modal-avancar-status')" variant="outline-danger">
                    Cancelar
                  </b-button>
                  <b-button @click.prevent.stop="updateCompStatus()" variant="outline-success">
                    Confirmar
                  </b-button>
                </div>
              </b-overlay>
            </template>
          </b-modal>


          <!-- Início Histórico de Compensação -->
          <b-tab v-if="detail.inComp">
            <template #title>
              <font-awesome-icon icon="history"></font-awesome-icon>
              <strong> Historico de compensação</strong>
            </template>

            <div v-for="(it, i) in detail.fasesStatus.statusCompensacoes" :key="i">
              <h4 v-b-toggle="it.mesAnoReferencia">
                Mês referente: {{ it.mesAnoReferencia }}
                <span class="when-open">
                  <font-awesome-icon icon="chevron-down"></font-awesome-icon>
                </span>
                <span class="when-closed">
                  <font-awesome-icon icon="chevron-right"></font-awesome-icon>
                </span>
              </h4>

              <b-collapse :id="it.mesAnoReferencia" class="py-2">
                <div class="t" v-for="(item, index) in it.statusCompensacaoMes" :key="index">
                  <div>
                    {{ statusComp[item.idStatusCompensacaoAnterior] }}
                    <font-awesome-icon icon="long-arrow-alt-right"></font-awesome-icon>
                    {{ statusComp[item.idStatusCompensacao] }}
                  </div>
                  <div>
                    <strong>Alterado em: </strong>
                    {{ item.dataAlteracao }}
                    <strong> por: </strong>
                    {{ item.usuarioAlteracao }}
                  </div>
                  <hr />
                </div>
              </b-collapse>
            </div>
          </b-tab>

          <!-- Inicio Contrato -->
          <b-tab id="tab-contrato">
            <template #title>
              <font-awesome-icon icon="file-contract"></font-awesome-icon>
              <strong> Contrato </strong>
            </template>
            <b-alert show variant="warning" style="
                display: flex;
                justify-content: center;
                align-items: center;
              ">
              <font-awesome-icon icon="exclamation-triangle" style="margin-right: 5px"></font-awesome-icon>Em
              construção...
            </b-alert>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
    <!-- End - Modal detalhes da opotunidade -->
  </section>
</template>

<script>
import emitter from "../../services/emitter";
import HomeMethods from "../../Utilities/HomeMethods";
import HomeService from "../../services/home.service";
import OportunidadeService from "@/services/oportunidade.service";

export default {
  name: "DetailOportunidadeHome",
  data() {
    return {
      statusComp: [
        "Início da compensação",
        "Aguardando documentação para compensação.",
        "Em processo de compensação.",
        "Em processo de faturamento.",
        "Faturado.",
      ],

      btnCompStt: {
        color: "#1E541C",
        nome: "Documentos recebidos",
      },
    };
  },

  props: { detail: Object, openModalOp: Boolean, itemsTable: Array },

  computed: {
    StatusCompensacao() {
      let status =
        this.statusComp[
        this.detail.fasesStatus.statusCompensacoes[
          this.detail.fasesStatus.statusCompensacoes.length - 1
        ].statusCompensacaoMes[
          this.detail.fasesStatus.statusCompensacoes[
            this.detail.fasesStatus.statusCompensacoes.length - 1
          ].statusCompensacaoMes.length - 1
        ].idStatusCompensacao
        ];
      return status;
    },

    FormatDateHours() {
      if (this.detail.outrasInf.dAlteracao) {
        let Data = this.detail.outrasInf.dAlteracao.split(" ");
        Data = Data[0];
        return Data;
      }
    },
  },

  watch: {
    openModalOp(newValue) {
      if (newValue) {
        this.$refs["modalOportunidade"].show();
        this.$emit("resetModal", false);
      }
    },

    detail(newValue) {
      if (newValue) this.detail = newValue;
    },
  },

  mounted() {
    // emitter.on("newValueDetail", (detailData) => {
    //   console.log("teste: ", detailData);
    // });
  },

  methods: {
    assignOportunidade(user, op) {
      OportunidadeService.attrOp(user, op).then((response) => {
        if (response.data === true && this.itemsTable.some((el) => el == op)) {
          this.itemsTable[this.itemsTable.indexOf(op)].outrasInf.responsavel =
            this.$store.state.auth.user.username;

          const msg = `Oportunidade atribuido para: ${this.$store.state.auth.user.username}.`;
          const type = "success";
          emitter.emit("makeModalDefault", { msg, type });
        }
      });
    },

    unassignOportunidade(omie, op) {
      OportunidadeService.desattrOp(omie, op).then(() => {
        this.detail.outrasInf.responsavel = "Omie";
        const msg = `Oportunidade desatribuida.`;
        const type = "success";
        emitter.emit("makeModalDefault", { msg, type });
      });
    },

    async modifiedModal() {
      if (this.btnCompStt.nome == "Documentos recebidos") {
        // this.$bvModal.show("modal-avancar-status");
        const status = this.statusComp[
          this.detail.fasesStatus.statusCompensacoes[
            this.detail.fasesStatus.statusCompensacoes.length - 1
          ].statusCompensacaoMes[
            this.detail.fasesStatus.statusCompensacoes[
              this.detail.fasesStatus.statusCompensacoes.length - 1
            ].statusCompensacaoMes.length - 1
          ].idStatusCompensacao + 1
        ].trim()


        this.$swal({
          position: "center",
          icon: "info",
          html: `Você realmente deseja avançar o status: <br> <strong>${status}</strong>`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          confirmButtonColor: "#233873",
          cancelButtonText: `Não`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$swal({
              html: "<span class='spinner-border m-3'></span>",
              showConfirmButton: false,
            })
            this.updateCompStatus();
          }
        })

      } else if (this.btnCompStt.nome == "Enviar para faturamento") {
        this.$bvModal.show("modal-lg");
      }
    },

    editCalc() {
      // console.log("🎲 ", this.detail);
      // this.$router.push(`/calculo/${this.detail.tese.tesePrimaria}/${this.detail.nCodOp
      //   }/`)
    },

    updateCompStatus() {

      HomeService.updateCompStatus(
        this.detail,
        this.$store.state.auth.user.username
      ).then((data) => {
        this.detail.fasesStatus.statusCompensacoes = data;
        const returnBtn = HomeMethods.btnStatus(
          this.detail,
          this.btnCompStt,
          this.$store.state.auth.user.roles
        );
        this.btnCompStt = returnBtn.btn;
        this.$swal.close()
      });
    },
  },
};
</script>






<style src="./DetailOportunidadeHomeStyles.scss" lang="scss" scoped />