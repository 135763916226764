<template>
  <div id="filtros">


      <b-form @submit.stop.prevent="sendFilter()" class="d-flex justify-content-between cabecalho-inputs">
        
        <div id="filter-input-container" 
        class="cabecalho-tools d-flex justify-content-between flex-row">
          <input id="filter-input" v-model="form.nomeEmpresa" type="text"
            class="cabecalho-input" placeholder="Empresa, CNPJ, Email..."/>
          <button :disabled="loadingFilter" class="btn" type="submit" variant="dark">
            <Icon :iconName='"search"' class="nav-icon"/>
          </button>
        </div>

        <div class="cabecalho-tools cabecalho-btn">
          <b-form-select 
          class="cabecalho-input" id="cabecalho-dropdown"
            v-model="form.statusOp" 
            :options="[
              { value: null, text: 'Todas oportunidades' },
              'LEAD',
              'COBRAR DOCS',
              'ESTUDO DE VIABILIDAD',
              'APRESENTAÇÃO',
              'NEGOCIAÇÃO',
              'CONCLUSÃO',
            ]" 
          :value="null">
          </b-form-select>
        </div>

        <div class="">
          <button class="cabecalho-tools cabecalho-btn" @click.prevent="clearFilter" v-if="form.nomeEmpresa || form.statusOp ">
            <Icon :iconName='"x-lg"' class="nav-icon"/>
          </button>
        </div>
      </b-form>


  </div>
</template>

<script>
import HomeService from "../../services/home.service";
import DateRangePicker from "vue2-daterange-picker";
import Icon from '../../Components/Icon/icon.vue';
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

let today = new Date();
today.setHours(0, 0, 0, 0);
let todayEnd = new Date();
todayEnd.setHours(11, 59, 59, 999);

let yesterdayStart = new Date();
yesterdayStart.setDate(today.getDate() - 1);
yesterdayStart.setHours(0, 0, 0, 0);

let yesterdayEnd = new Date();
yesterdayEnd.setDate(today.getDate() - 1);
yesterdayEnd.setHours(11, 59, 59, 999);

let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
let thisMonthEnd = new Date(
  today.getFullYear(),
  today.getMonth() + 1,
  0,
  11,
  59,
  59,
  999
);

export default {
  data() {
    return {
      picker: { startDate: "", endDate: "" },
      pressed: false,
      filters: [],
      dateRange: {
        startDate: today,
        endDate: today,
      },
      customRange: {
        Hoje: [today, today],
        Ontem: [yesterdayStart, yesterdayEnd],
        "Este mês": [thisMonthStart, thisMonthEnd],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
      },
      form: {
        cnpj: "",
        nomeEmpresa: "",
        email: "",
        statusOp: null,
        dataAtt: "",
      },
      numberRowsItems: 0,
      loadingFilter: false,
    };
  },


  created() {
    this.numberRowsItems = this.Pages;
    this.sendFilter()
  },

  props: ["dados", "numberPagination", "Pages", "titlePage"],

  watch: {
    numberPagination(newValue) {

      HomeService.getHome(
        this.form.cnpj,
        this.form.nomeEmpresa,
        this.form.email,
        this.form.statusOp,
        this.$store.state.auth.user,
        this.form.dataAtt,
        newValue,
        this.numberRowsItems
      ).then((data) => {
        this.$emit("submit-filter", data);
        this.$emit("busy", false);
      });
    },

    'form.statusOp':function (){
      this.sendFilter();
    },
  },

  methods: {
    clearFilter() {
      this.dateRange = { startDate: today, endDate: today };
      this.form = { cnpj: "", nomeEmpresa: "", email: "", statusOp: null };
    },

    formatDate(date) {
      let dateFormated = " ";
      if (date != null || date != " ") {
        dateFormated =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear();
      } else {
        alert("Insira uma data válida!");
      }
      return dateFormated;
    },

    sendFilter() {
      this.$emit("busy", true);
      this.loadingFilter = true
      this.form.dataAtt = {
        startDate: this.formatDate(this.dateRange.startDate),
        endDate: this.formatDate(this.dateRange.endDate),
      };

      HomeService.getHome(
        this.form.cnpj,
        this.form.nomeEmpresa,
        this.form.email,
        this.form.statusOp,
        this.$store.state.auth.user,
        this.form.dataAtt,
        1,
        this.numberRowsItems
      ).then((data) => {
        this.loadingFilter = false
        this.$emit("submit-filter", data);
        this.$emit("busy", false);
      });
    },
  },

  components: { DateRangePicker, Icon },
};
</script>


<style src="./HomeFilter.scss" lang="scss" />
