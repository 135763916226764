import axios from "axios";

const API_URL = process.env.VUE_APP_URL_API;

class HomeService {
  async getHome(cnpj, nomeEmpresa, email, statusOp, user, ultimaAtt, pag, perPage) {
    try {      
      var buscaSemFiltro;
      if(cnpj == "" && nomeEmpresa == "" && email == "" && statusOp == null) 
        buscaSemFiltro = true;
      else
        buscaSemFiltro = false;

      return await axios
        .post(API_URL + "/Oportunidade/GetOportunidadesPesquisa/", {
          CNPJ: cnpj,
          NomeEmpresa: nomeEmpresa,
          Email: email,
          Status: statusOp,
          UltimaAtualizacao: ultimaAtt,
          BuscaSemFiltro: buscaSemFiltro,
          PageInfo: {
            NextPage: pag,
            ItemsPerPage: perPage,
            TotalPages: 0,
          },
          User: {
            EmailUser: user.emailUser,
            Roles: user.roles,
            Username: user.username,
            AccessToken: user.accessToken,
          },
        })
        .then((resp) => {
          if (resp.status == 200) {
            return resp.data;
          }
        });
    } catch (err) {
      console.error("❌ Error Get Home: ", err.message);
    }
  }

  async updateCompStatus(Op, Usuario) {
    try {
      let NCodOp = Op.nCodOp;
      let Email = Op.email;
      let idStatusCompensacao =
        Op.fasesStatus.statusCompensacoes[
          Op.fasesStatus.statusCompensacoes.length - 1
        ].statusCompensacaoMes[
          Op.fasesStatus.statusCompensacoes[
            Op.fasesStatus.statusCompensacoes.length - 1
          ].statusCompensacaoMes.length - 1
        ].idStatusCompensacao;

      return await axios
        .post(API_URL + "/Oportunidade/AtualizarStatus/", {
          NCodOp,
          Email,
          idStatusCompensacao,
          Usuario,
        })
        .then((resp) => {
          if (resp.status == 200) {
            return resp.data;
          }
        });
    } catch (err) {
      console.error("❌ Error UpdatedCompStatus: ", err.message);
    }
  }
}

export default new HomeService();
